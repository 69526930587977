import Trans from 'next-translate/Trans'
import Link from 'next/link'
import style from '../LicensingModal.module.css'
export const errorsOptions = {
  404: {
    /**
     * System is being rebooted
     */
    151: {
      hasError: true,
      message: <Trans i18nKey="cameras:licensingModal.errors.rebooting"></Trans>,
    },
  },
  400: {
    /**
     *  Invalid ticket ID
     */
    131: {
      hasError: true,
      message: (
        <Trans
          i18nKey="cameras:licensingModal.errors.process"
          components={[
            <Link
              key="mail-support"
              type="danger"
              className={style.supportLink}
              href="mailto:support@automatic.tv"
            >
              <Trans i18nKey="cameras:supportEmail"></Trans>
            </Link>,
          ]}
        />
      ),
    },
    /**
     *  Invalid license for device type
     */
    136: {
      hasError: true,
      message: (
        <Trans
          i18nKey="cameras:licensingModal.errors.notValid"
          components={[
            <Link
              key="mail-support"
              type="danger"
              className={style.supportLink}
              href="mailto:support@automatic.tv"
            >
              <Trans i18nKey="cameras:supportEmail"></Trans>
            </Link>,
          ]}
        />
      ),
    },
    /**
     *  Already used license
     */
    137: {
      hasError: true,
      message: (
        <Trans
          i18nKey="cameras:licensingModal.errors.alreadyActivated"
          components={[
            <Link
              key="mail-support"
              type="danger"
              className={style.supportLink}
              href="mailto:support@automatic.tv"
            >
              <Trans i18nKey="cameras:supportEmail"></Trans>
            </Link>,
          ]}
        />
      ),
    },
  },
}
